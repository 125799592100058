// Shippio's Design System Colors
// https://www.figma.com/file/8P3qZ8wi4oNBrFP9GoFpr5/Shippio---Design-System-file?node-id=411%3A2

// Primary Colors
$primary-navy-6: #4C5193;
$primary-navy-7: #000665;
$primary-navy-8: #000446;

// Base Colors
$base-grey-2: #F8F8F8;
$base-grey-3: #EEEEEE;
$base-grey-4: #DEE0E0;
$base-grey-5: #A1A1A1;
$base-grey-6: #3A3E46;

// Neutral Colours
$neutral-base-1: #FFFFFF;
$neutral-base-2: #F8F8F8;
$neutral-base-4: #DEE0E0;

// Accent Colors
$accent-blue-2: #CDDAF9;
$accent-blue-5: #5A83EC;
$accent-blue-8: #24345E;

$accent-green-4: #2AE61E;

// Attention Colors
$attention-red-1: #FFF7F5;
$attention-red-3: #FBEBE7;
$attention-red-5: #D53612;
$attention-red-7: #80200B;

$attention-yellow-5: #E8A701;

// Category Colors
$category-blue-5: #2F8DFA;

// Old Deprecated Color Variables - please replace as you go
// Shippio theme colors
$shippio-blue: #000665;
$shippio-dark-blue: rgb(0, 4, 70);
$shippio-green: #2AE61E;
$shippio-light-blue: #5A83EC;

// UI colors
$light-blue: #E5EAF7;
$light-grey: #F8F8F8;
$light-black: #3A3E46; // use $base-grey-6
$light-green: #EBF9EB;
$medium-grey: #EEEEEE;
$grey: #8F8F8F;
$black:  #000000;
$light-grey-blue: #E8E9F1;
$text-dark-grey: #A1A1A1; // $base-grey-5
$blue-grey: #69739B;
$dark-blue-grey: #8A8F9C;
$white: #FFFFFF;
$red:  #f44336;
$error-text: #D53612;

// Other UI
$read-only-grey: #6C6C6C;
$placeholder-grey: #DEE0E0;
$disabled-background-grey: #F9F9F9;
$disabled-font-grey: #888A8D;
$hover-over-grey: #EEEEEE;

// Shipment colors
$shipment-blue: #5A83EC;

// Shipment Milestone Colors
$milestones-not-completed: #3A3E46; // $base-grey-6
$milestones-default: #A1A1A1; // $base-grey-5
$milestones-completed: #5A83EC;
$milestones-warning: #E8A701;
$milestones-critical: #D53612;

// Basic table colors
$gray-divider: #DEE0E0;
$header-font-color: #8F8F8F;
$rows-font-color: #6C6C6C;
$label-color: #8F8F8F;

// Simulation
$vessel-schedule-item-border: #C4C4C4;
$input-border: #E4E5ED;

// Chat, info-circle
$partner-orange: #FF8600;

// Notification List

// This color is used in Figma, but is not in the color system...
$list-item-border-grey: #EAEBEA;
