@import '../../../assets/styles/dimensions';
@import '../../../assets/styles/functions';
@import '../../../assets/styles/colors';
@import '../../../assets/styles/animations';

.topbar {
  position: fixed;
  left: $sidebar-width;
  right: 0;
  top: 0;
  height: $secondary-navigation-height;
  background-color: $neutral-base-1;
  z-index: get-z-index('top-bar');
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.25));
  // temporarily just spread buttons out on the right side
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  transition: left $animation-speed__side-bar;

  &__notification {
    svg {
      height: 1.5rem;
      fill: $primary-navy-7;
    }
  }

  // Overwriting Material UI Button default style
  .MuiButton-root {
    min-width: 0;
    padding: 8px 12px;
  }

  .sidebar-collapsed & {
    left: $sidebar-width-collapsed;
  }
}
